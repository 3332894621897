// @file Helpers for Library related stuff
import { __ } from '@@/bits/intl'
import { LibraryMembershipRole, LibraryType } from '@@/enums'
import type { OnboardingTask, OnboardingTaskData } from '@@/types'

export const teamLibraryOnboardingDataByTask: Partial<Record<OnboardingTask, OnboardingTaskData>> = {
  addNewTeam: {
    key: 'addNewTeam',
    icon: 'group',
    taskStringOne: __('Add a new team'),
    taskStringTwo: __('Create a space for team members to make and share padlets.'),
    taskButtonText: __('Create new team'),
    taskButtonLabel: __('Create new team'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 1,
  },
  customizeTeamInfo: {
    key: 'customizeTeamInfo',
    icon: 'pencil_outline',
    taskStringOne: __('Customize team info'),
    taskStringTwo: __('Pick a great team name and avatar in Settings > Team info.'),
    taskButtonText: __('Team info'),
    taskButtonLabel: __('Go to team info settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 2,
  },
  addMembers: {
    key: 'addMembers',
    icon: 'add_account_outline',
    taskStringOne: __('Add team members'),
    taskStringTwo: __('Invite new members to your team in Settings > Members > Invite.'),
    taskButtonText: __('Members'),
    taskButtonLabel: __('Go to team members settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 3,
  },
  pickPlan: {
    key: 'pickPlan',
    icon: 'credit_outline',
    taskStringOne: __('Pick the best plan'),
    taskStringTwo: __('Explore team plan options in Settings > Billing.'),
    taskButtonText: __('Billing'),
    taskButtonLabel: __('Go to team billing settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 4,
  },
  makePrivateWall: {
    key: 'makePrivateWall',
    icon: 'password',
    taskStringOne: __('Make a private padlet'),
    taskStringTwo: __(
      'Change padlet privacy from Team only to Private by clicking Make > Share > Change privacy > Private.',
    ),
    taskButtonText: __('Make a padlet'),
    taskButtonLabel: __('Make a padlet'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 5,
  },
}

export const schoolLibraryOnboardingDataByTask: Partial<Record<OnboardingTask, OnboardingTaskData>> = {
  addNewTeam: {
    key: 'addNewTeam', // addNewTeam is used across teams and schools
    icon: 'group',
    taskStringOne: __('1. Create a new school account'),
    taskStringTwo: __('Create a space for teachers and students to make new padlets.'),
    taskButtonText: __('Create new school'),
    taskButtonLabel: __('Create new school'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 1,
  },
  addAdmins: {
    key: 'addAdmins',
    icon: 'add_account_outline',
    taskStringOne: __('2. Add admins'),
    taskStringTwo: __('Invite new admins in Settings > Members > Add admins.'),
    taskButtonText: __('Members'),
    taskButtonLabel: __('Go to school members settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 2,
  },
  connectSchoolSso: {
    key: 'connectSchoolSso',
    icon: 'fingerprint',
    taskStringOne: __("3. Connect your organization's SSO"),
    taskStringTwo: __('Make logging in easier by setting up SSO for your organization'),
    taskButtonText: __('Security'),
    taskButtonLabel: __('Go to organization info security settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 3,
  },
  manageUserPermissions: {
    key: 'manageUserPermissions',
    icon: 'password',
    taskStringOne: __('4. Manage user permissions'),
    taskStringTwo: __('Set permissions for what users are allowed to access and create.'),
    taskButtonText: __('Permissions'),
    taskButtonLabel: __('Go to school permission settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 4,
  },
  addTeachersAndStudents: {
    key: 'addTeachersAndStudents',
    icon: 'add_account_outline',
    taskStringOne: __('5. Add teachers and students'),
    taskStringTwo: __('Invite new teachers and students in Settings > Members > Add teachers and students.'),
    taskButtonText: __('Members'),
    taskButtonLabel: __('Go to school members settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 5,
  },
}

export const classroomLibraryOnboardingDataByTask: Partial<Record<OnboardingTask, OnboardingTaskData>> = {
  addNewTeam: {
    key: 'addNewTeam',
    icon: 'group',
    taskStringOne: __('Add a new classroom'),
    taskStringTwo: __('Create a space for students and teachers to make and share padlets.'),
    taskButtonText: __('Create new classroom'),
    taskButtonLabel: __('Create new classroom'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 1,
  },
  customizeTeamInfo: {
    key: 'customizeTeamInfo',
    icon: 'pencil_outline',
    taskStringOne: __('Customize classroom info'),
    taskStringTwo: __('Pick a great classroom name and avatar in Settings > Classroom info.'),
    taskButtonText: __('Classroom info'),
    taskButtonLabel: __('Go to classroom info settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 2,
  },
  addMembers: {
    key: 'addMembers',
    icon: 'add_account_outline',
    taskStringOne: __('Add students'),
    taskStringTwo: __('Invite new students to your classroom in Settings > Members > Invite.'),
    taskButtonText: __('Members'),
    taskButtonLabel: __('Go to classroom members settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 3,
  },
  pickPlan: {
    key: 'pickPlan',
    icon: 'credit_outline',
    taskStringOne: __('Upgrade your classroom'),
    taskStringTwo: __('Keep creating padlets in your classroom when your trial ends by upgrading.'),
    taskButtonText: __('Billing'),
    taskButtonLabel: __('Go to classroom billing settings'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 4,
  },
  makePrivateWall: {
    key: 'makePrivateWall',
    icon: 'password',
    taskStringOne: __('Make a private padlet'),
    taskStringTwo: __('Make something just for you by clicking Make > Share > Change privacy > Private.'),
    taskButtonText: __('Make a padlet'),
    taskButtonLabel: __('Make a padlet'),
    isTaskDone: false,
    taskDoneAt: null,
    order: 5,
  },
}

export function getOnboardingDataByTask(task: OnboardingTask, libraryType: LibraryType): OnboardingTaskData {
  const libraryTypeToLibraryOnboardingDataByTask = {
    [LibraryType.Team]: teamLibraryOnboardingDataByTask,
    [LibraryType.School]: schoolLibraryOnboardingDataByTask,
    [LibraryType.Classroom]: classroomLibraryOnboardingDataByTask,
  }
  return libraryTypeToLibraryOnboardingDataByTask[libraryType][task]
}

export function getLibraryMembershipRoleTranslation(role: string): string {
  return {
    [LibraryMembershipRole.Owner]: __('Owner'),
    [LibraryMembershipRole.Admin]: __('Admin'),
    [LibraryMembershipRole.Maker]: __('Maker'),
    [LibraryMembershipRole.Teacher]: __('Teacher'),
    [LibraryMembershipRole.Student]: __('Student'),
    [LibraryMembershipRole.Contributor]: __('Contributor'),
  }[role as LibraryMembershipRole]
}

export function getLibraryMembershipRoleTranslationByLibrary(
  role: LibraryMembershipRole,
  libraryType: LibraryType = LibraryType.Team,
): string {
  return {
    [LibraryType.Classroom]: {
      [LibraryMembershipRole.Owner]: __('Teacher (owner)'),
      [LibraryMembershipRole.Teacher]: __('Teacher'),
      [LibraryMembershipRole.Student]: __('Student'),
    },
    [LibraryType.Team]: {
      [LibraryMembershipRole.Owner]: __('Owner'),
      [LibraryMembershipRole.Admin]: __('Admin'),
      [LibraryMembershipRole.Maker]: __('Maker'),
      [LibraryMembershipRole.Contributor]: __('Contributor'),
    },
    [LibraryType.School]: {
      [LibraryMembershipRole.Owner]: __('Owner'),
      [LibraryMembershipRole.Admin]: __('Admin'),
      [LibraryMembershipRole.Teacher]: __('Teacher'),
      [LibraryMembershipRole.Student]: __('Student'),
    },
  }[libraryType][role]
}
